@import './modal-close-button'
@import './modal-overlay'
@import './modal-overrides'

.spot-modal
  position: relative
  display: flex
  flex-direction: column
  align-items: stretch
  background: white
  pointer-events: all

  border-radius: $spot-spacing-0-25
  width: 40rem

  max-width: calc(100vw - 2rem)
  max-height: calc(100vh - 2rem)

  overflow-y: auto

  @media (max-width: 680px)
    width: calc(100vw - 2rem)
    max-height: calc(100vh - 5rem)

    @media (max-height: 480px)
      max-height: calc(100vh - 2rem)
      max-width: calc(100vw - 6rem)

  &_wide
    width: 60rem
    min-height: 40vh

  &--header
    @include spot-subheader-big

    display: flex
    padding: $spot-spacing-1 $spot-spacing-1 0
    position: relative
    align-items: center

    + .spot-divider
      margin-top: $spot-spacing-1
  
    > .spot-icon
      width: $spot-spacing-1-5
      height: $spot-spacing-1-5
      margin-right: $spot-spacing-0-5
      font-size: 1rem

    &_highlight
      background-color: var(--header-bg-color)
      border-bottom-width: var(--header-border-bottom-width)
      border-bottom-color: var(--header-border-bottom-color)

      *
        color: var(--header-item-font-color)

  &--body
    @include styled-scroll-bar

    display: flex
    flex-direction: column
    flex-grow: 1
    flex-shrink: 1
    overflow-y: auto

    &:focus
      outline-style: none

  &--cancel-button
    @media (max-width: 680px)
      display: none
