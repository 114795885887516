.spot-tooltip
  position: relative
  display: inline-flex

  &--body
    @include spot-z-index("tooltip", 1)
    pointer-events: none
    opacity: 0
    width: calc(100% - #{$spot-spacing-2})
    left: $spot-spacing-1

    position: absolute
    height: auto

    box-shadow: $spot-shadow-light-low
    background: $spot-color-basic-gray-1
    color: $spot-color-basic-white

    display: flex
    flex-direction: column

    &_left-top
      right: 100%

    &_left-center
      right: 100%
      top: 50%
      transform: translateY(-50%)

    &_left-bottom
      right: 100%

    &_bottom-left
      top: 100%
      left: $spot-spacing-1

    &_bottom-center
      top: 100%
      left: 50%
      transform: translateX(-50%)

    &_bottom-right
      top: 100%
      right: $spot-spacing-1

    &_right-top
      left: 100%

    &_right-center
      left: 100%
      top: 50%
      transform: translateY(-50%)

    &_right-bottom
      left: 100%

    &_top-left
      bottom: 100%
      left: $spot-spacing-1

    &_top-center
      bottom: 100%
      left: 50%
      transform: translateX(-50%)

    &_top-right
      bottom: 100%
      right: $spot-spacing-1

    .spot-body-small
      &:first-child
        margin-top: $spot-spacing-0-5

      &:last-child
        margin-bottom: $spot-spacing-0-5

  &:hover &--body
    opacity: 1

  &_light &--body
    color: $spot-color-basic-gray-1
    background: $spot-color-main-light
