.spot-modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 9000
  background: rgba(0, 0, 0, 0.75)
  justify-content: center
  align-items: center
  display: none

  &_active
    display: flex
    flex-direction: column-reverse

    @media (max-width: 680px)
      justify-content: end

    @media (max-height: 480px)
      // reorder elements to be displayed in row on very small heights
      flex-direction: row-reverse

  &_not-full-screen
    background: transparent
    right: unset
    bottom: unset
    top: initial
